import React, { useState } from 'react'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'

import SEO from '@components/SEO'
import Layout from '@components/Layout'
import Section from '@components/Section'
import Heading from '@components/Heading'
import LayoutHeroMobile from '@components/Layout/Layout.Hero.Mobile'
import Image from '@components/Image'
import ScrollIndicator from '@components/ScrollIndicator'
import Pill from '@components/Pill'
import Transitions from '@components/Transitions'

import media from '@styles/media'
import { keyToSymbol } from '@shortcuts'

import LabsPreview from '../../sections/labs/Labs.Preview'
import LabsPreviewMobile from '../../sections/labs/Labs.Preview.Mobile'

/**
 * The labs page is a bit of a mess with the inlined Product Array but it
 * works for now. In the event that we redesign this page it's recommended
 * to start a new file to handle things neater!
 */

function LabsPage({ data, location }) {
  const {
    allContentfulPage,
    hero,
    heroBody,
    heroScreen,
    needlBackground,
    memoirBackground,
    feyBackground,
    novelaBackground,
    commandlineBackground,
  } = data
  const { seo } = allContentfulPage.edges[0].node
  const pageBackground =
    'linear-gradient(180deg, #08070B 40%, #111216 50%, #191D23 100%)'
  const navConfig = {
    fixed: true,
    theme: 'light',
    offset: true,
  }
  const footerConfig = {
    visible: true,
  }

  // Start the bulb up animation once the image has laoded
  const [showScreen, setShowScreen] = useState(false)

  // Inlining our products to get the right variables we need in scope!
  const products = [
    {
      logo: NeedlLogo,
      background: needlBackground.childImageSharp.fluid,
      backgroundColor: '#060608',
      excerpt:
      "DESCRIPTION HERE",
      children: (
        <>
          <HorizontalRule dark />
          <LinkToProduct dark as="div">
            Coming Soon: Request for early notification?? Press C
          </LinkToProduct>
        </>
      ),
    },
    // {
    //   logo: FeyLogo,
    //   background:feyBackground.childImageSharp.fluid,
    //   backgroundColor: '#A2C4E0',
    //   excerpt:
    //   "DESCRIPTION HERE",
    //   children: (
    //     <>
    //       <HorizontalRule dark />
    //       <LinkToProduct dark as="div">
    //        Press C For Demo Request
    //       </LinkToProduct>
    //     </>
    //   ),
    // },
    {
      logo: NovelaLogo,
      background: novelaBackground.childImageSharp.fluid,
      backgroundColor: '#BCECE0',
      excerpt:
        "DESCRIPTION HERE",
      children: (
        <>
          <HorizontalRule dark />
          <LinkToProduct dark as="div">
            Coming Soon: Request for early notification?? Press C
          </LinkToProduct>
        </>
      ),
    },
    {
      logo: CommandlineLogo,
      background: commandlineBackground.childImageSharp.fluid,
      backgroundColor: '#DAD870',
      excerpt:
      "DESCRIPTION HERE",
      children: (
        <>
          <HorizontalRule dark />
          <LinkToProduct dark as="div">
            Coming Soon: Request for early notification?? Press C
          </LinkToProduct>
        </>
      ),
    },
    {
      logo: MemoirLogo,
      background: memoirBackground.childImageSharp.fluid,
      backgroundColor: '#060608',
      excerpt:
      "DESCRIPTION HERE",
      children: (
        <>
          <HorizontalRule dark />
          <LinkToProduct dark as="div">
            Coming Soon: Request for early notification?? Press C
          </LinkToProduct>
        </>
      ),
    }
  ].reverse()

  return (
    <Layout nav={navConfig} background={pageBackground} footer={footerConfig}>
      <>
        <SEO
          title={seo.title}
          description={seo.description}
          image={seo.image.file.url}
          pathname={location.pathname}
        />
        <LayoutHeroMobile>
          <HeroSection hideOverflow>
            <ContentContainer>
              <div />
              <Transitions.CSS.FadeIn>
                <TextContainer>
                  <Pill text="Recent Projects" />
                  <Heading.h2 styles="h1">
                    We have already built products for schools, hospitals, governments and entrepreneurs all around the globe.
                  </Heading.h2>
                  <MainText>
                    Below are some of our current projects that we have been working on.
                  </MainText>
                </TextContainer>
              </Transitions.CSS.FadeIn>
              <ScrollIndicator />
            </ContentContainer>

          </HeroSection>
        </LayoutHeroMobile>
        <HeroImageMobile>
          <Image src={hero.childImageSharp.fluid} />
        </HeroImageMobile>
        <LabsBody>
          <Section narrow>
            {products.map(product => (
              <LabsPreview key={product.excerpt} product={product} />
            ))}
          </Section>
          <Section narrow>
            <LabsPreviewMobile />
          </Section>
        </LabsBody>
      </>
    </Layout>
  )
}

export default LabsPage

export const pageQuery = graphql`
  query LabsPageQuery {
    allContentfulPage(filter: { pageName: { eq: "Labs" } }) {
      edges {
        node {
          seo {
            title
            description
            image {
              file {
                url
              }
            }
          }
        }
      }
    }
    hero: file(name: { regex: "/labs-hero-phone/" }) {
      childImageSharp {
        fluid(maxWidth: 1060, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    heroBody: file(name: { regex: "/labs-floating-phone-body/" }) {
      childImageSharp {
        fluid(maxWidth: 1060, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    heroScreen: file(name: { regex: "/labs-floating-phone-screen/" }) {
      childImageSharp {
        fluid(maxWidth: 1060, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    needlBackground: file(name: { regex: "/labs-needl-desktop/" }) {
      childImageSharp {
        fluid(maxWidth: 793, maxHeight: 381, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    feyBackground: file(name: { regex: "/labs-fey-desktop/" }) {
      childImageSharp {
        fluid(maxWidth: 793, maxHeight: 381, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    commandlineBackground: file(name: { regex: "/labs-command-desktop/" }) {
      childImageSharp {
        fluid(maxWidth: 793, maxHeight: 381, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    novelaBackground: file(name: { regex: "/labs-novela-desktop/" }) {
      childImageSharp {
        fluid(maxWidth: 793, maxHeight: 381, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    memoirBackground: file(name: { regex: "/labs-memoir-desktop/" }) {
      childImageSharp {
        fluid(maxWidth: 793, maxHeight: 381, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

const HeroSection = styled(Section)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${media.tablet`
    pointer-events: none;
  `};
`

const HeroImage = styled.div`
  position: relative;
  width: 610px;
  top: -180px;
  right: -40px;
  animation: float 4.8s ease-in-out infinite 1.4s;

  @keyframes float {
    0% {
      transform: translatey(0px);
    }
    50% {
      transform: translatey(-8px);
    }
    100% {
      transform: translatey(0px);
    }
  }

  ${media.tablet`
    display: none;
  `};

  .gatsby-image-wrapper {
    position: absolute !important;
    top: -180px;
    left: 0;
    width: 100%;
  }
`

const HeroImageMobile = styled(HeroImage)`
  display: none;

  ${media.tablet`
    box-shadow: 0px -40px 40px rgba(8, 7, 11, 0.8);
    display: block;
    width: 100%;
    top: 0;
    right: 0;
    margin-bottom: 60px;
  `};

  .gatsby-image-wrapper {
    position: relative !important;
    top: 0;
    left: 0;
    width: 100%;
  }
`

const TextContainer = styled.div`
  max-width: 560px;

  ${media.phablet`
    position: relative;
    top: -50px;
  `}
`

const MainText = styled.p`
  font-size: 3.2rem;
  font-weight: 400;
  color: ${p => p.theme.colors.grey};
  line-height: 1.3;

  ${media.phablet`
    font-size: 2.2rem;
  `};
`

const ContentContainer = styled.div`
  height: calc(100vh - 140px);
  min-height: 440px;

  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${media.tablet`
    height: calc(100vh - 90px);
    padding: 0;
    user-select: none;
    pointer-events: none;
  `};
`

const HorizontalRule = styled.hr`
  width: 140px;
  height: 1px;
  border: none;
  margin-bottom: 30px;
  background: ${p => (p.dark ? 'rgba(0, 0, 0, 0.1)' : 'rgba(255,255,255,0.1)')};

  ${media.phablet`
    width: 100%;
    margin: 0 auto 25px;
    background: ${p =>
      p.dark ? 'rgba(0, 0, 0, 0.1)' : 'rgba(255,255,255,0.1)'};
  `}
`

const LabsBody = styled.div`
  position: relative;
  z-index: 1;
`

const LinkToProduct = styled.a`
  position: relative;
  font-weight: 600;
  font-size: 16px;
  color: ${p => (p.dark ? p.theme.colors.grey : '#fff')};

  svg {
    margin-right: 13px;
  }

  &:nth-child(2) {
    margin-left: 30px;
  }

  &[data-a11y='true']:focus::after {
    content: '';
    position: absolute;
    left: -10%;
    top: -42%;
    width: 120%;
    height: 200%;
    border: 2px solid ${p => p.theme.colors.purple};
    background: rgba(255, 255, 255, 0.01);
    border-radius: 5px;
  }

  ${media.tablet`
    display: block;
    margin: 0 auto;
    color: ${p => (p.dark ? '#000' : '#fff')};

    &:nth-child(2) {
      margin: 15px auto 0;
    }

      svg {
        display: none;
      }
  `}
`

const Symbol = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  min-width: 16px;
  text-align: center;
  border-radius: 2.5px;
  padding: 1px 2px;
  color: ${p => p.theme.colors.bg};
  background: ${p => p.theme.colors.moon};
  font-size: 12px;
  font-weight: 400;
  margin-left: 5px;
`

const NeedlLogo = () => (
  <MainText>MarketPlace</MainText>
)

const FeyLogo = () => (
  <MainText>Blockchain</MainText>
)

const CommandlineLogo = () => (
  <MainText>Inventory Management</MainText>
)

const NovelaLogo = () => (
  <MainText>Realtime</MainText>
)

const MemoirLogo = () => (
  <MainText>Hotels and Industries</MainText>
)


